<script lang="ts">
	import { onMount } from 'svelte';
	import {
		Button,
		Col,
		Row,
		Jumbotron,
		Image
	} from '@sveltestrap/sveltestrap';
	import type { ApiResponseSuccess, Product } from '../types/types';
	import ProductModal from '../components/Modals/ProductModal.svelte';
	import ProductCard from '../components/ProductCard.svelte';
	import { worker, products } from '../stores';
	import { push } from 'svelte-spa-router';
	import SelectedProductsList from '../components/SelectedProductsList.svelte';
	import { sendCompleteProductionData, toastThemes } from '../utils/productionUtils';

	let isModalOpen = false;
	let selectedProduct: Product | null = null;

	// Fetch products from the API on component mount
	onMount(async () => {
		if (!$worker) {
			await push('/');
			return;
		}
		console.log($worker);
		try {
			const response = await Espo.Ajax.getRequest(`Product/productionProducts/${$worker}`);
			const data = (response as ApiResponseSuccess<Product[]>).data;
			products.set(data);
		} catch (error) {
			console.error('Failed to fetch products:', error);
		}
	});

	const openModal = (product: Product) => {
		selectedProduct = product;
		isModalOpen = true;
	};

	const closeModal = () => {
		isModalOpen = false;
		selectedProduct = null;
	};

	function logoutUser() {
		worker.set(null);
		push("/");
	}

	async function completeProduction() {
		await sendCompleteProductionData();
		worker.set(null);
		products.set([])
		await push('/');
	}
</script>

<ProductModal isOpen={isModalOpen} product={selectedProduct} onClose={closeModal} />

<Row>
	<Col>
		<Jumbotron>
			<Button color="outline-danger" class="m-2" on:click={logoutUser}>Odhlásit se</Button>
			<Row>
				<Col class="d-flex align-items-center mr-5">
					<h1 class="display-4" style="white-space: nowrap; margin-right: 6rem;">
						Portál Výroby
					</h1>
				</Col>
				<Col>
					<Image
						fluid
						alt="AutoERP"
						src="client/modules/production-portal/lib/production-portal/autoerp-brand-logo-dark.png"
					/>
				</Col>
			</Row>
			<hr />
			<div class="products-section">
				{#if products}
					<Row class="products-grid">
						{#each $products as product}
							<Col>
								<ProductCard {product} onSelect={openModal} />
							</Col>
						{/each}
					</Row>
				{:else}
					<p>Načítám produkty...</p>
				{/if}
			</div>
			<hr />
			<SelectedProductsList />

			{#if $products.filter(p => p.producedQuantity > 0).length > 0}
				<Row class="mt-4">
					<Col class="text-center">
						<Button color="primary" on:click={completeProduction}>
							Dokončit výrobu
						</Button>
					</Col>
				</Row>
			{/if}
		</Jumbotron>
	</Col>
</Row>

<style>
	.products-section {
		margin-bottom: 3rem;
	}

	:global(.products-grid) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}

	/* Button Styling */
	a.btn-primary {
		width: 100%;
		height: 3em;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		font-size: x-large;
	}

	/* General Styling */
	span {
		display: block;
		height: fit-content;
	}

	h5.card-title {
		font-size: xx-large;
	}

	.quantity-display {
		margin-top: 0.5rem;
		font-weight: bold;
		text-align: center;
	}

	.selected-products-list {
		margin-top: 2rem;
	}

	.selected-products-list .table {
		width: 100%;
		color: #333;
	}

	.selected-products-list .table th, .selected-products-list .table td {
		text-align: center;
		vertical-align: middle;
	}

	.selected-products-list input[type="number"] {
		width: 80px;
		margin: 0 auto;
		display: block;
	}
</style>
