// src/utils/api.ts

import type { ApiResponse } from '../types/types';
import { products, worker } from '../stores';
import { get } from 'svelte/store';
import type { AjaxPromise } from 'espocrm/src/ajax';

/**
 * Sends production data to the backend.
 * @returns A promise that resolves to the backend response.
 */
export async function sendProductionData(): Promise<ApiResponse<any>> {
	try {
		const response = await (Espo.Ajax.postRequest(
			'ProductionOrder/createOrUpdateProduction',
			{
				products: get(products),
				productionWorkerId: get(worker),
			},
		) as AjaxPromise<ApiResponse<any>>);

		return response;
	} catch (error) {
		console.error('Failed to send production data:', error);
		throw error;
	}
}
