<script lang="ts">
	import {
		Modal,
		ModalHeader,
		ModalBody,
		ModalFooter,
		Button,
		Input
	} from '@sveltestrap/sveltestrap';
	import type { Product } from '../../types/types';
	import { products } from '../../stores';
	import { toast } from '@zerodevx/svelte-toast';
	import { get } from 'svelte/store';
	import { onMount, onDestroy } from 'svelte';
	import { tick } from 'svelte';
	import { updateProductsAndSendData } from '../../utils/productionUtils';

	export let isOpen: boolean;
	export let product: Product | null;
	export let onClose: () => void;

	let desiredQuantity: number = 1;
	let showConfirm: boolean = false;
	let isSubmitting: boolean = false;

	// Mounted flag to prevent state updates after unmounting
	let mounted = false;

	onMount(() => {
		mounted = true;
	});

	onDestroy(() => {
		mounted = false;
	});

	// Previous state to detect when modal is opened
	let prevIsOpen = false;

	// Reactive statement to initialize desiredQuantity only when the modal is newly opened
	$: {
		if (isOpen && !prevIsOpen && product) {
			const currentProduct = get(products).find((p) => p.id === product.id);
			desiredQuantity =
				currentProduct && currentProduct.producedQuantity > 0
					? currentProduct.producedQuantity
					: 1; // Default to 1 if producedQuantity is 0 or undefined
			showConfirm = false;
		}
		prevIsOpen = isOpen;
	}

	/**
	 * Saves the desired quantity to the store with validation.
	 * If quantity exceeds available stock, show confirmation step.
	 */
	async function saveQuantity() {
		if (desiredQuantity < 1) {
			toast.push('Množství musí být alespoň 1.', {
				theme: {
					'--toastBackground': '#dc3545', // Error color
					'--toastColor': '#fff'
				}
			});
			return;
		}

		if (product) {
			const availableQuantity = product.quantity;

			if (desiredQuantity > availableQuantity) {
				// Show confirmation instead of immediate saving
				showConfirm = true;
				return;
			}

			// Proceed to update and send production data using the utility function
			isSubmitting = true;
			try {
				await updateProductsAndSendData((currentProducts) =>
					currentProducts.map((p) =>
						p.id === product.id
							? { ...p, producedQuantity: desiredQuantity }
							: p
					)
				);
				if (mounted) {
					onClose(); // Close modal only if component is still mounted
				}
			} catch (error) {
				// Optional: Handle additional errors if necessary
			} finally {
				isSubmitting = false;
				await tick();
			}
		}
	}

	/**
	 * Confirms the saving of quantity that exceeds available stock.
	 */
	async function confirmSave() {
		if (product) {
			// Proceed to update and send production data using the utility function
			isSubmitting = true;
			try {
				await updateProductsAndSendData((currentProducts) =>
					currentProducts.map((p) =>
						p.id === product.id
							? { ...p, producedQuantity: desiredQuantity }
							: p
					)
				);
				if (mounted) {
					onClose(); // Close modal only if component is still mounted
				}
			} catch (error) {
				// Optional: Handle additional errors if necessary
			} finally {
				isSubmitting = false;
				await tick();
			}
		}
	}

	/**
	 * Cancels the confirmation step.
	 */
	function cancelConfirm() {
		showConfirm = false;
	}
</script>

{#if isOpen && product}
	<Modal isOpen={isOpen} toggle={onClose} centered>
		<ModalHeader toggle={onClose}>{product.name}</ModalHeader>
		<ModalBody>
			<img class="product-image" src={product.image} alt={product.name} />
			<p><strong>EAN:</strong> {product.ean}</p>
			<div class="quantity-input">
				<label for="quantity">Kolik produktů chcete vyrobit:</label>
				<Input
					id="quantity"
					type="number"
					min="1"
					bind:value={desiredQuantity}
					class="form-control mt-2"
					disabled={isSubmitting}
				/>
			</div>
			{#if showConfirm}
				<div class="confirm-section">
					<p class="text-warning">
						Požadované množství ({desiredQuantity}) překračuje dostupné množství ({product.quantity}).
						Opravdu chcete pokračovat?
					</p>
				</div>
			{/if}
		</ModalBody>
		<ModalFooter>
			{#if showConfirm}
				<Button
					color="danger"
					on:click={cancelConfirm}
					disabled={isSubmitting}
					aria-label="Zrušit"
				>
					Zrušit
				</Button>
				<Button
					color="warning"
					on:click={confirmSave}
					disabled={isSubmitting}
					aria-label="Potvrdit"
				>
					{#if isSubmitting}
						Ukládání...
					{:else}
						Potvrdit
					{/if}
				</Button>
			{:else}
				<Button
					color="secondary"
					on:click={onClose}
					disabled={isSubmitting}
					aria-label="Zavřít"
				>
					Zavřít
				</Button>
				<Button
					color="primary"
					on:click={saveQuantity}
					disabled={isSubmitting}
					aria-label="Uložit"
				>
					{#if isSubmitting}
						Ukládání...
					{:else}
						Uložit
					{/if}
				</Button>
			{/if}
		</ModalFooter>
	</Modal>
{/if}

<style>
	.product-image {
		width: 100%;
		height: auto;
		border-radius: 4px;
	}

	.quantity-input {
		margin-top: 1rem;
	}

	.confirm-section {
		margin-top: 1rem;
	}

	.text-warning {
		color: #ffc107; /* Bootstrap warning color */
	}

	/* Optional: Customize the modal transitions or overrides if needed */
</style>
