<script lang="ts">
	import { onMount } from 'svelte';
	import type { Product } from '../types/types';
	import { products } from '../stores';
	import { toast } from '@zerodevx/svelte-toast';
	import { get } from 'svelte/store';
	import { tick } from 'svelte';
	import { updateProductsAndSendData } from '../utils/productionUtils';
	import { pop, push } from 'svelte-spa-router';
	import { createEventDispatcher } from 'svelte';

	export let params;

	let product: Product | null = null;
	let desiredQuantity: string | null = null;
	let isSubmitting: boolean = false;
	let showCustomInput: boolean = false;
	let showConfirm: boolean = false;
	let quantityInput: HTMLInputElement;

	onMount(() => {
		product = $products.find(p => p.id === params.id);
		if (!product) {
			toast.push('Produkt nebyl nalezen.', {
				theme: {
					'--toastBackground': '#dc3545',
					'--toastColor': '#fff'
				}
			});
			push('/');
			return;
		}
		initializeQuantity();
	});

	function initializeQuantity() {
		const currentProduct = get(products).find((p) => p.id === product!.id);
		if (currentProduct && currentProduct.producedQuantity > 0) {
			desiredQuantity = currentProduct.producedQuantity.toString();
		} else {
			desiredQuantity = null;
		}
		showCustomInput =
			!product!.productDefaultProductionQuantity ||
			product!.productDefaultProductionQuantity.length === 0;
	}

	async function saveQuantity() {
		const qtyNumber = parseInt(desiredQuantity || '', 10);
		if (isNaN(qtyNumber) || qtyNumber < 1) {
			toast.push('Množství musí být alespoň 1.', {
				theme: {
					'--toastBackground': '#dc3545',
					'--toastColor': '#fff'
				}
			});
			return;
		}

		if (product) {
			await performSave();
		}
	}

	async function confirmSave() {
		if (product) {
			await performSave();
		}
	}

	async function performSave() {
		isSubmitting = true;
		try {
			await updateProductsAndSendData((currentProducts) =>
				currentProducts.map((p) =>
					p.id === product!.id
						? { ...p, producedQuantity: parseInt(desiredQuantity || '1', 10) }
						: p
				)
			);
		} catch (error) {
			console.error('Chyba při ukládání:', error);
			toast.push('Nastala chyba při ukládání.', {
				theme: {
					'--toastBackground': '#dc3545',
					'--toastColor': '#fff'
				}
			});
		} finally {
			isSubmitting = false;
			await tick();
			await pop();
		}
	}

	function cancelConfirm() {
		showConfirm = false;
	}

	function handlePresetQuantity(qty: number) {
		desiredQuantity = qty.toString();
		showConfirm = false;
		saveQuantity();
	}

	async function handleCustomQuantity() {
		showCustomInput = true;
		showConfirm = false;
		await tick();
		if (quantityInput) {
			//quantityInput.focus();
		}
	}

	function goBack() {
		pop();
	}

	function handleKeypadClick(key: string) {
		console.log(key);
		if (key === 'C') {
			desiredQuantity = null;
		} else if (key === '<') {
			desiredQuantity = desiredQuantity?.slice(0, -1) || null;
		} else {
			if (desiredQuantity === '0') {
				desiredQuantity = key;
			} else {
				desiredQuantity = (desiredQuantity || '') + key;
			}
		}
	}

	$: {
		if (desiredQuantity?.startsWith('0') && desiredQuantity.length > 1) {
			desiredQuantity = desiredQuantity.replace(/^0+/, '');
		}
	}
</script>

<div class="container mt-4" style="text-align: center">
	{#if product}
		<h2>{product.name}</h2>
		<div class="image-container">
			<img class="product-image" src={product.image} alt={product.name} />
		</div>

		{#if showCustomInput || (!product.productDefaultProductionQuantity || product.productDefaultProductionQuantity.length === 0)}
			<div class="quantity-input">
				<input
					id="quantity"
					type="text"
					bind:value={desiredQuantity}
					class="form-control mt-2 enlarged-input"
					disabled={true}
					bind:this={quantityInput}
					aria-label="Vlastní množství přímo zadání"
					pattern="\d*"
					inputmode="numeric"
				/>
			</div>

			{#if showCustomInput}
				<div class="keypad">
					{#each ['1','2','3','4','5','6','7','8','9','0'] as key}
						<button
							type="button"
							class="btn btn-secondary keypad-button"
							on:click={() => handleKeypadClick(key)}
							disabled={isSubmitting}
							aria-label={`Číslice ${key}`}
						>
							{key}
						</button>
					{/each}
				</div>

				<div class="keypad-bottom">
					<button
						type="button"
						class="btn btn-secondary keypad-button"
						style="background-color: #dc3545"
						on:click={() => handleKeypadClick('C')}
						disabled={isSubmitting}
						aria-label="Vymazat"
					>
						C
					</button>
					<button
						type="button"
						class="btn btn-secondary keypad-button"
						style="background-color: #dc3545"
						on:click={() => handleKeypadClick('<')}
						disabled={isSubmitting}
						aria-label="Zpět"
					>
						&lt;
					</button>
				</div>
			{/if}
		{/if}

		{#if product.productDefaultProductionQuantity && product.productDefaultProductionQuantity.length > 0 && !showCustomInput}
			<div class="quantity-buttons-panel">
				<div class="buttons-grid">
					{#each product.productDefaultProductionQuantity as qty}
						<button
							type="button"
							class="btn btn-primary btn-lg quantity-button enlarged-button"
							on:click={() => handlePresetQuantity(qty)}
							disabled={isSubmitting}
							aria-label={`Vybrat množství ${qty}`}
						>
							{qty}
						</button>
					{/each}
				</div>

				<button
					type="button"
					class="btn btn-success btn-lg custom-quantity-button enlarged-button"
					on:click={handleCustomQuantity}
					disabled={isSubmitting}
					aria-label="Zadat vlastní množství"
				>
					Zadat vlastní množství
				</button>
			</div>
		{/if}

		{#if showConfirm}
			<div class="confirm-section">
				<p class="text-warning">
					Požadované množství ({desiredQuantity}) překračuje dostupné množství ({product.quantity}).
					Opravdu chcete pokračovat?
				</p>
				<div class="confirm-buttons">
					<button
						type="button"
						class="btn btn-danger enlarged-button"
						on:click={cancelConfirm}
						disabled={isSubmitting}
						aria-label="Zrušit"
					>
						Zrušit
					</button>
					<button
						type="button"
						class="btn btn-warning enlarged-button"
						on:click={confirmSave}
						disabled={isSubmitting}
						aria-label="Potvrdit"
					>
						{#if isSubmitting}
							Ukládání...
						{:else}
							Potvrdit
						{/if}
					</button>
				</div>
			</div>
		{/if}

		<div class="button-row">
			<button
				type="button"
				class="btn btn-secondary enlarged-button btn-half"
				on:click={goBack}
				aria-label="Zpět"
			>
				Zpět
			</button>
			{#if !showConfirm && showCustomInput}
				<button
					type="button"
					class="btn btn-primary enlarged-button btn-half"
					on:click={saveQuantity}
					disabled={isSubmitting || !desiredQuantity}
					aria-label="Uložit množství"
				>
					{#if isSubmitting}
						Ukládání...
					{:else}
						Uložit množství
					{/if}
				</button>
			{/if}
		</div>
	{/if}
</div>

<style>
	.product-image {
		width: 375px;
		height: auto;
		border-radius: 4px;
		margin-bottom: 1rem;
	}

	.quantity-input {
		margin-top: 1rem;
	}

	.quantity-buttons-panel {
		margin-top: 1.5rem;
	}

	.quantity-button,
	.custom-quantity-button,
	.confirm-buttons button,
	.button-row button {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
		margin-bottom: 1rem;
		height: 100px;
		border-radius: 4px;
		width: 100%;
	}

	.buttons-grid {
		display: grid;
		gap: 1rem;
		margin-bottom: 1rem;
		grid-template-columns: 1fr 1fr;
	}

	.image-container {
		height: 375px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
	}

	.enlarged-input {
		font-size: 1.2rem;
		padding: 0.75rem 1.5rem;
	}

	.text-warning {
		color: #ffc107;
	}

	.confirm-section {
		margin-top: 1rem;
	}

	.confirm-buttons {
		display: flex;
		gap: 1rem;
		margin-top: 1rem;
	}

	.button-row {
		display: flex;
		gap: 1rem;
		justify-content: flex-start;
		margin-top: 1rem;
	}

	.btn-half {
		flex: 1;
	}

	.btn-lg {
		height: 100px;
	}

	.button-row button {
		width: 100%;
	}

	.keypad {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
		margin: 1rem 0;
	}

	.keypad > :last-child {
		grid-column: 2;
	}

	.keypad-bottom {
		display: flex;
		gap: 1rem;
		grid-column: span 3;
	}

	.keypad-button {
		width: 100%;
		height: 7.5em;
		font-size: 1.5rem;
		border-radius: 4px;
	}

</style>
