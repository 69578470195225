import { products, worker } from '../stores';
import { get } from 'svelte/store';
import { sendProductionData } from './api';
import { toast } from '@zerodevx/svelte-toast';
import type { ApiResponse, Product } from '../types/types';
import type { AjaxPromise } from 'espocrm/src/ajax';

export const toastThemes = {
	success: {
		'--toastBackground': '#28a745',
		'--toastColor': '#fff',
	},
	warning: {
		'--toastBackground': '#ffc107',
		'--toastColor': '#fff',
	},
	error: {
		'--toastBackground': '#dc3545',
		'--toastColor': '#fff',
	},
};

export async function updateProductsAndSendData(
	updateFn: (currentProducts: Product[]) => Product[],
): Promise<void> {
	const updatedProducts = updateFn(get(products));
	products.set(updatedProducts);

	try {
		const response: ApiResponse<any> = await sendProductionData();

		if (response.success) {
			toast.push('Výrobní data byla úspěšně odeslána.', {
				theme: toastThemes.success,
			});
		} else {
			toast.push(
				`Chyba: ${response.message || 'Chyba při odesílání dat.'}`,
				{
					theme: toastThemes.error,
				},
			);
		}
	} catch (error) {
		toast.push('Výrobní data byla odeslána ale nastala chyba v procesu.', {
			theme: toastThemes.warning,
		});
	}
}

export async function sendCompleteProductionData(): Promise<void> {
	try {
		const response: ApiResponse<any> = await (Espo.Ajax.postRequest(
			'ProductionOrder/completeProductionForWorker',
			{
				productionWorkerId: get(worker),
			},
		) as AjaxPromise<ApiResponse<any>>);

		if (response.success) {
			toast.push('Výroba byla úspěšně dokončena.', {
				theme: toastThemes.success,
			});
		} else {
			toast.push(
				`Chyba: ${response.message || 'Chyba při odesílání dat.'}`,
				{
					theme: toastThemes.error,
				},
			);
		}
	} catch (error) {
		toast.push('Chyba při odesílání dat na server.', {
			theme: toastThemes.error,
		});
	}
}
