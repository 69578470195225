<script lang="ts">
	import type { ApiResponseSuccess, EspoResponseList, Product, ProductionWorker } from '../types/types';
    import { Jumbotron, Row, Col, Image} from "@sveltestrap/sveltestrap";
	import type { AjaxPromise } from 'espocrm/src/ajax';
	import Button from '../components/Button.svelte';
	import WorkerCard from '../components/WorkerCard.svelte';
	import { onMount } from 'svelte';

    let workers = null;
    let columnsCount = 2;

	onMount(() => {
		try {
			(Espo.Ajax.getRequest("ProductionWorker",{select: 'id,name,photoId'}) as AjaxPromise<EspoResponseList<ProductionWorker>>)
				.then((response) => {
					workers = response.list;
					console.log(workers);
				});
		} catch (error) {
			console.error('Failed to fetch products:', error);
		}
	});

   function exitKiosk():void {
        console.log("exit");
        document.title="#EXITKIOSK";
        return;
    }
</script>


<Row>
    <Col>
        <Jumbotron>
            <Row>
                <Col>
                    <a color="danger" class="m-2 btn  btn-outline-danger" href="/"
                        >Zpět do CRM</a
                    >
                </Col>
                <Col >
                    <Button color="danger" class="m-2"
                        on:click={() => exitKiosk()}
                        style="float: right;"
                    >X
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col class="d-flex align-items-center mr-5">
                <h1 class="display-4"
                    style="white-space: nowrap; margin-right: 6rem;">
                    Portál Výroby

                </h1>
                </Col>
                <Col>
                <Image
                        fluid
                        alt="AutoERP"
                        src="client/modules/production-portal/lib/production-portal/autoerp-brand-logo-dark.png"
                    />
                </Col>
            </Row>
            <hr />
            <Row class="mb-3">
                <Col class="d-flex"  style="align-items: center;">
                    <h3 >Prosím přihlaste se:</h3>
                </Col>

            </Row>
			{#if workers != null}
				{#each Array(Math.ceil(workers.length / columnsCount)) as _, i}
					<Row >
						{#each workers.slice(i * columnsCount, i*columnsCount+columnsCount) as worker, j}
							<WorkerCard
								name={worker.name}
								id={worker.id}
								columnsCount={columnsCount}
								imageUrl={worker.photoId ? `/?entryPoint=image&id=${worker.photoId}` : "https://placehold.co/400"}
							/>
						{/each}
					</Row>
				{/each}
			{:else }
				<p>Načítám pracovníky...</p>
			{/if}


        </Jumbotron>
    </Col>
</Row>
