<script lang="ts">
	import { link } from "svelte-spa-router";
	import { Col } from "@sveltestrap/sveltestrap";
	import { worker} from "../stores";

	export let id: string;
	export let columnsCount: number;
	export let name = "zamestnanec";
	export let imageUrl = "https://placehold.co/400";

	function loginUser() {
		worker.set(id);
	}
</script>

<Col style="max-width: calc( 100% / {columnsCount})">
	<div class="card mt-2">
		<img src={imageUrl} class="card-img-top" alt="x"/>
		<div class="card-body" >
			<h5 class="card-title mb-4 mt-2 text-center">{name}</h5>
			<div class="col text-center">
				<a href="/production" class="btn btn-primary btn-lg btn-block" use:link on:click={loginUser}
				><span>Přihlásit se</span></a
				>
			</div>
		</div>
	</div>
</Col>

<style>

	img.card-img-top {
		max-height: 864px;
		height: auto;
		max-width: 632px;
		margin: 20px auto;
	}
	@media (max-width: 768px) {
		img.card-img-top {
			margin: 0 auto;
		}
	}
	a.btn-primary {
		width: 100%;
		height: 3em;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		font-size: x-large;
	}
	span {
		height: fit-content;
	}

	h5.card-title {
		font-size: xx-large;
	}

</style>
