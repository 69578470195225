<script lang="ts">
	import { Card, CardBody, CardTitle } from '@sveltestrap/sveltestrap';
	import type { Product } from '../types/types';

	export let product: Product;
	export let onSelect: (product: Product) => void;

	// Handle keyboard events for accessibility
	function handleKeydown(event: KeyboardEvent) {
		if (event.key === 'Enter' || event.key === ' ') {
			event.preventDefault();
			onSelect(product);
		}
	}
</script>

<div
	class="product-card"
	class:runningLow={product.isRunningLow}
	role="button"
	tabindex="0"
	on:click={() => onSelect(product)}
	on:keydown={handleKeydown}
>
	<Card>
		<div class="image-container">
			<img src={product.image} class="card-img-top" alt={product.name} />
		</div>
		<CardBody>
			<CardTitle class="text-center h5">{product.name}</CardTitle>
			<div class="product-info text-center">
				<span>EAN: {product.ean}</span><br />
				<span>Dostupné množství: {product.quantity}</span><br />
				<span>Požadované množství: {product.minimumStockQuantity}</span><br />
				<span class="quantity-display">Vyrobené množství: {product.producedQuantity}</span>
			</div>
		</CardBody>
	</Card>
</div>

<style>
	.product-card {
		width: 100%;
		margin-top: 0.5rem;
		cursor: pointer;
		outline: none;
	}

	.image-container {
		height: 416px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
	}

	.card-img-top {
		max-width: 100%;
		max-height: 100%;
	}

	.runningLow {
		color: #ff3f4b;
	}
</style>
