<script lang="ts">
	import { products } from '../stores';
	import { Button, Table } from '@sveltestrap/sveltestrap';
	import { updateProductsAndSendData } from '../utils/productionUtils';

	/**
	 * Removes the producedQuantity for a specific product by setting it to 0.
	 * @param productId - The ID of the product to update.
	 */
	function removeProducedQuantity(productId: string) {
		updateProductsAndSendData((currentProducts) =>
			currentProducts.map((p) =>
				p.id === productId ? { ...p, producedQuantity: 0 } : p
			)
		);
	}
</script>

<div class="selected-products-list">
	<h2>Vybrané Produkty k Výrobě</h2>
	{#if $products.filter(p => p.producedQuantity > 0).length > 0}
		<Table striped>
			<thead>
			<tr>
				<th>Název produktu</th>
				<th>EAN</th>
				<th>Vybrané množství</th>
				<th>Akce</th>
			</tr>
			</thead>
			<tbody>
			{#each $products.filter(p => p.producedQuantity > 0) as product}
				<tr>
					<td>{product.name}</td>
					<td>{product.ean}</td>
					<td>{product.producedQuantity}</td>
					<td>
						<Button color="danger" size="sm" on:click={() => removeProducedQuantity(product.id)}>
							Odebrat
						</Button>
					</td>
				</tr>
			{/each}
			</tbody>
		</Table>
	{:else}
		<p>Žádné vybrané produkty.</p>
	{/if}
</div>

<style>
	.selected-products-list {
		margin-top: 2rem;
	}

	.table {
		width: 100%;
		color: #333;
	}

	.table th, .table td {
		text-align: center;
		vertical-align: middle;
	}

</style>
